import React, {useEffect} from 'react';
import { BrowserRouter, Route, Routes} from "react-router-dom";
import ReactGA from "react-ga"
import{ init } from 'emailjs-com';
import {Home} from './components/Home';
import {Extras} from './components/Extras';
import {About} from './components/About';
import {Contact} from './components/Contact';
import {MMIW} from './components/MMIW';
import {JUNETEENTH} from './components/JUNETEENTH';
import {IBC2020} from './components/IBC2020';
import {IBC2021} from './components/IBC2021';
import {IBC2022} from './components/IBC2022';
import {IBC2023} from './components/IBC2023';
import {IBC2024} from './components/IBC2024';
import {IBC2025} from './components/IBC2025';
import {HolyDays} from './components/HolyDays';
import {Default} from './components/Default';
import {NavigationBar} from "./components/NavigationBar";
import {Circles} from "./components/Circle";
import {Footer} from "./components/Footer";
import {Firesides} from "./components/Firesides";
import {Music} from "./components/Music";
import {SaggezzaIndex} from "./components/Saggezza/SaggezzaIndex";
import {Numero1} from "./components/Saggezza/Numero1";

// @ts-ignore
import styled from "styled-components";
import { createBrowserHistory } from 'history';
import { Writings } from './components/Writings';
import { Team } from './components/Team';
import { Chants } from './components/Chants';

const StyledFooter = styled(Footer)`
  color: brown;
  background-color : #222;
  padding-top: 0;
  //position:;
  bottom:0;
  width:100%;
  ;
`;

const App: React.FC = () => {
    useEffect(() => {
        init("user_D2ukNNOeACmhwvOKwZ6nw");
        ReactGA.initialize('UA-186421508-1');
        ReactGA.pageview(window.location.pathname);
    })

    const history = createBrowserHistory();

    // Initialize google analytics page view tracking
    history.listen(location => {
        ReactGA.initialize('UA-186421508-1');
        // @ts-ignore
        ReactGA.set({ page: location.pathname }); // Update the user's current page
        // @ts-ignore
        ReactGA.pageview(location.pathname); // Record a pageview for the given page
    });

    // @ts-ignore
    return (
              <React.Fragment>
                  <NavigationBar/>
                  {/*<Layout>*/}
                      <BrowserRouter basename={"/"}>
                          <Routes>
                              <Route path={"/"} element={<Home/>} />;
                              <Route path={"/mmiw"} element={<MMIW/>} />;
                              <Route path={"/juneteenth"} element={<JUNETEENTH/>} />;
                              <Route path={"/ibc2020"} element={<IBC2020/>} />;
                              <Route path={"/ibc2021"} element={<IBC2021/>} />;
                              <Route path={"/ibc2022"} element={<IBC2022/>} />;
                              <Route path={"/ibc2023"} element={<IBC2023/>} />;
                              <Route path={"/ibc2024"} element={<IBC2024/>} />;
                              <Route path={"/ibc2025"} element={<IBC2025/>} />;
                              <Route path={"/holydays"} element={<HolyDays/>} />;
                              <Route path={"/chants"} element={<Chants/>} />;
                              <Route path={"/extras"} element={<Extras/>} />;
                              <Route path={"/circles"} element={<Circles/>} />;
                              <Route path={"/writings"} element={<Writings/>} />;
                              <Route path={"/about"} element={<About/>} />;
                              <Route path={"/contact"} element={<Contact/>} />;
                              <Route path={"/team"} element={<Team/>} />;
                              <Route path={"/firesides"} element={<Firesides/>} />;
                              <Route path={"/music"} element={<Music/>} />;
                              <Route path={"/Saggezza/Saggezza_index"} element={<SaggezzaIndex/>} />;
                              <Route path={"/Saggezza/:id"} element={<Numero1/>} />;
                               <Route element={<Default/>} />;
                          </Routes>
                      </BrowserRouter>
                  {/*</Layout>*/}

                 <StyledFooter/>
              </React.Fragment>
  );
};

export default App;
