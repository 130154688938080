import React from 'react';
import {Accordion} from 'react-bootstrap';
//import {SoundCloudPlayer} from "./SoundCloudPlayer";
//import {YouTubePlayer} from "./YouTubePlayer";

// @ts-ignore
export const IBC2025 = () => (
    <div>
        <p/>
        <h2>Indigenous Bahá'í Ceremony 2025</h2>
        <Accordion defaultActiveKey="47">
            <Accordion.Item eventKey="47">
                <Accordion.Header>
                    <center>
                        <h4><a href={"https://us02web.zoom.us/j/82163574991?pwd=0dgffj01etaL7CfhfkUSsLV9pJh6dc.1"}>
                            Click here at 2pm PST Sunday 9th February 2025 to join the live Zoom session</a></h4>
                            <img src={process.env.PUBLIC_URL + "/images/Ceremony/Ceremony Flyer 2025_02.jpg"}
                                 alt={"Gathering of the Relatives"}
                                 width={"45%"} height={"45%"}/>
                    </center>
                </Accordion.Header>
                <Accordion.Body>
                    <div>
                        <h4>
                           video and audio coming soon.
                        </h4>
                    </div>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    </div>
)

